const initialState = {
  userData: {},
  isAuthenticated: true,
  currentUser: { fullName: "Admin", email: "admin@wowplex.com" },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_AUTH": {
      return { ...state, currentUser: action.payload, isAuthenticated: true };
    }
    case "PURGE_AUTH": {
      return { ...state, currentUser: null, isAuthenticated: false };
    }
    default:
      return state;
  }
};

export default authReducer;
